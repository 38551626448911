<template>
    <div class="user-role-setting-view">
        <div class="ctrl-view">
            <div class="label">平台角色：</div>
            <n-select
                class="input"
                style="width: 150px"
                size="small"
                :options="roleOptions"
                :consistent-menu-width="false"
                v-model:value="selectedRole"
                @update:value="onSelectedRoleChange"
            ></n-select>
            <n-button
                class="submit"
                size="small"
                type="primary"
                @click="showAddUserModal = true"
            >
                <template #icon>
                    <n-icon>
                        <AddSharp></AddSharp>
                    </n-icon>
                </template>
                添加用户
            </n-button>
        </div>
        <div class="data-view">
            <n-data-table :columns="tableColumns" :data="tableData">
            </n-data-table>
        </div>
        <n-modal v-model:show="showAddUserModal">
            <n-card class="add-user-view" style="width: 500px" title="增加用户">
                <n-input
                    v-model:value="addUserName"
                    placeholder="输入LDAP用户名"
                ></n-input>
                <template #footer>
                    <div class="footer">
                        <n-button
                            style="margin-left: auto"
                            type="primary"
                            @click="onAddUserToRole"
                            >添加</n-button
                        >
                    </div>
                </template>
            </n-card>
        </n-modal>
    </div>
</template>

<style lang="less" scoped>
@import '../../../common/common.less';

.user-role-setting-view {
    .common-content;
    .ctrl-view {
        .common-ctrl-bar;
    }

    .data-view {
        margin-top: 10px;
        min-height: 700px;
    }
}

.add-user-view {
    .footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}
</style>

<script setup>
import { h, ref } from 'vue';
import {
    NButton,
    NSelect,
    NDataTable,
    NIcon,
    useDialog,
    useMessage,
    NModal,
    NCard,
    NInput,
} from 'naive-ui';
import { AddSharp, DeleteSharp } from '@vicons/material';

import { SettingsAPI } from '@/common/API';

const dialog = useDialog();

const message = useMessage();

let roleOptions = ref([]);

let selectedRole = ref('');

function onDeleteUser(row) {
    dialog.info({
        title: `移除用户`,
        content: `确定将用户${row.username}从用户组[${selectedRole.value}]移除?`,
        positiveText: '确定',
        positiveButtonProps: {
            type: 'primary',
        },
        negativeText: '取消',
        onPositiveClick: () => {
            console.log('delete user' + row.username);
            message.loading('操作中...');
            SettingsAPI.removeUserToRole(row.username, selectedRole.value).then(
                res => {
                    message.destroyAll();
                    if (res.error_no !== 0) {
                        message.error(res.error_msg || '未知异常导致操作失败!');
                        return;
                    }
                    loadUsers();
                }
            );
        },
    });
}

let tableColumns = ref([
    { title: '用户', key: 'username' },
    {
        title: '操作',
        width: 80,
        render(row) {
            return h(
                NButton,
                {
                    size: 'tiny',
                    onClick: () => onDeleteUser(row),
                },
                {
                    default: () =>
                        h(NIcon, null, {
                            default: () => h(DeleteSharp),
                        }),
                }
            );
        },
    },
]);
let tableData = ref([]);

let showAddUserModal = ref(false);

let addUserName = ref('');

function onAddUserToRole() {
    if (addUserName.value === '') {
        message.warning('请填写要添加的LDAP用户名');
        return;
    }
    message.loading('操作中...');
    SettingsAPI.addUserToRole(addUserName.value, selectedRole.value).then(
        res => {
            if (res.error_no !== 0) {
                message.error(res.error_msg || '未知异常导致操作失败!');
                return;
            }
            showAddUserModal.value = false;
            addUserName.value = '';
            loadUsers();
        }
    );
}

function onSelectedRoleChange(e) {
    loadUsers(e);
}

function loadUsers(role) {
    message.loading('努力加载中...');
    SettingsAPI.getUsersByRole(role || selectedRole.value).then(res => {
        message.destroyAll();
        if (res.error_no !== 0) {
            message.error(res.error_msg);
            return;
        }
        tableData.value = res.data.user_list.map(item => {
            return { username: item };
        });
    });
}

SettingsAPI.getRoles().then(res => {
    if (res.error_no !== 0) {
        message.error(res.error_msg);
        return;
    }
    let roles = res.data.role_list.map(item => {
        return { label: item, value: item };
    });
    roleOptions.value = roles;
    selectedRole.value = roles[0].value;
    loadUsers();
});
</script>
